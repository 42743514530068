<template>
  <div>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="isRtl"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="STATUS_COLOR[props.row.status.name]">
            {{ props.row.status.name }}
          </b-badge>
        </span>
        <span v-if="props.column.field === 'mentor'">
          {{ mentorInMatch(props.row) }}
        </span>
        <span v-if="props.column.field === 'mentee'">
          {{ menteeInMatch(props.row) }}
        </span>
        <span v-if="props.column.field === 'compat'">
          <b-link
            href="#"
            @click="openCompareModal(props.row)"
          >
            <apexchart
              height="100"
              type="radialBar"
              :options="options"
              :series="[props.row.compatibility]"
              class="d-flex justify-content-center"
            />
          </b-link>
        </span>

        <!-- Column: Action -->
        <div
          v-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-align"
        >
          <div class="align-items-center mb-0 mt-0">
            <b-button
              v-if="props.row.status_id === matchStatus.ACTIVE || props.row.status_id === matchStatus.CANCELLED"
              class="btn btn-icon"
              variant=" btn-outline"
              :id="`popover-target-${props.row.id}`">
              <feather-icon
                icon="BarChartIcon"
                size="20"
              />
            </b-button>
            <b-popover
              v-if="props.row.status_id === matchStatus.ACTIVE || props.row.status_id === matchStatus.CANCELLED"
              :target="`popover-target-${props.row.id}`" triggers="hover" placement="top"
            >
              
              <p class="mb-0">
                <small><b>Surveys</b></small>
              </p>
              <p class="mb-50">
                <small>{{ surveysCount(props.row) }}</small>
              </p>
              
              <p class="mb-0">
                <small><b>Training</b></small>
              </p>
              <p class="mb-50">
                <small>{{ trainingsCount(props.row) }}</small>
              </p>

              <p class="mb-0">
                <small><b>Duration</b></small>
              </p>
              <p class="mb-50">
                <small>{{ formatDuration(props.row) }}</small>
              </p>
            </b-popover>
            <b-button
              variant="outline-none"
              class="nav action-menu"
  
            >
              <b-nav-item-dropdown
                class="btn-icon"
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  :to="{
                    name: 'champion-program-communications',
                    query: {
                      user_ids: props.row.match_users.map((u) => u.user_id),
                    },
                  }"
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Message</span>
                </action-dropdown-item>
                <action-dropdown-item
                  v-if="props.row.status_id === matchStatus.ACTIVE"
                  link-class="d-flex align-items-center"
                  :disabled="props.row.status_id === matchStatus.CANCELLED"
                  @click="unpublishMatch(props.row.id, props.row)"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadCloudIcon"
                    class="mr-50"
                  />
                  <span>Unpublish</span>
                </action-dropdown-item>
                <action-dropdown-item
                  v-if="props.row.status_id === matchStatus.LOCKED"
                  link-class="d-flex align-items-center"
                  @click="unLockMatch(props.row.id, props.row)"
                >
                  <feather-icon
                    size="16"
                    icon="UnlockIcon"
                    class="mr-50"
                  />
                  <span>Unlock Match</span>
                </action-dropdown-item>
                <action-dropdown-item
                  v-if="props.row.status_id === matchStatus.LOCKED"
                  link-class="d-flex align-items-center"
                  @click="publishMatch(props.row.id, props.row)"
                >
                  <feather-icon
                    size="16"
                    icon="UnlockIcon"
                    class="mr-50"
                  />
                  <span>Publish</span>
                </action-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  :to="{ name: 'champion-match-view', params: { id: defaultProgramId, matchId: props.row.id } }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                    class="mr-50"
                  />
                  <span>View</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </div>
        </div>
        <!-- Column: Common -->
        <span v-else-if="props.column.field === 'duration'">
          {{
            props.row.status.id !== matchStatus.SCHEDULED
              ? formatDuration(props.row)
              : ""     
          }}
        </span>
        <span v-else-if="props.column.field !== 'status'">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
    <user-application-summary-modal
      ref="userAppSummary"
      :match-users="matchUsers"
      :compare-percentage="comparePercentage"
      @clear="clearModal"
    />
    <publish-match-modal 
      ref="publishMatchModal"
      :matches="matchesToPublish"
      @updated="onPublish"
    />
  </div>
</template>
  
<script>
import { 
  BBadge,
  BLink, 
  BPopover, 
  BNavItemDropdown,
  BDropdownItem,
  BButton
} from "bootstrap-vue";
import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_FORMAT, GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, PRIMARY_DATE_FORMAT } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRolesDisplay } from '@models/userRoles';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { userRoles, matchStatusDisplay } from '@models';
import matchesService from '@/services/matchesService';
import { mapGetters } from 'vuex';
import { STATUS_COLOR, getFormatedStringFromDays } from "@/libs/utils";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import UserApplicationSummaryModal from '@/views/apps/champion/matches/UserApplicationSummaryModal.vue';
import { matchStatus } from '@models';
import { roleAlias } from "@/@aom-core/utils/utils";
import PublishMatchModal from "../champion/matches/PublishMatchModal.vue";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
  
export default {
  components: {
    ActionDropdownItem,
    BBadge,
    BLink,
    VueGoodTable,
    TablePagination,
    UserApplicationSummaryModal,
    BPopover,
    BNavItemDropdown,
    BDropdownItem,
    BButton,
    PublishMatchModal
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      perPage: 10,
      page: 1,
      columns: [],
      rows: [],
      columnFilters: [],
      sort: [],
      total: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },

      },
      STATUS_COLOR,
      matchUsers: [],
      comparePercentage: 0,
      matchesToPublish: [],
      matchStatus,
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'defaultProgramId', 'forceRefreshParticipantMatches']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },

  watch: {
    forceRefreshParticipantMatches() {
      if (this.forceRefreshParticipantMatches) {
        this.loadItems();
        this.$store.commit('programs/SET_FORCE_REFRESH_PARTICIPANT_MATCHES', false);
      }
    }
  },
  created() {
    this.columns = [
      {
        label: roleAlias(userRoles.MENTOR, this.defaultProgram),
        field: "mentor",
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: roleAlias(userRoles.MENTOR, this.defaultProgram),
        },
        width: "12em",
      },
      {
        label: roleAlias(userRoles.MENTEE, this.defaultProgram),
        field: "mentee",
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: roleAlias(userRoles.MENTEE, this.defaultProgram),
        },
        width: "12em",
      },
      {
        label: "Published",
        field: "started_at",
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: "Published",
        },
        width: "10em",
        type: 'date',
        dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
        dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
      },
      {
        label: "Completion",
        field: "ended_at",
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: "Completion",
        },
        width: "10em",
        type: 'date',
        dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
        dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
      },
      {
        label: "Compatibility",
        field: "compat",
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: "Compatibility",
        },
        thClass: "text-center", 
        tdClass: "text-center",
        width: "10em",
      },
      {
        label: "Duration",
        field: "duration",
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: "Duration",
        },
        thClass: "text-center",
        tdClass: "text-center",
        width: "8em",
      },
      {
        label: "Status",
        field: 'status',
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: "Status",
          filterDropdownItems: Object.values(matchStatusDisplay),
        },
        width: '8em'
      },
      {
        field: 'action',
        width: "8em",
        sortable: false
      }
    ];
    this.loadItems();
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    let datePickers = [];
    datePickers.push(document.getElementsByName("vgt-started_at"));
    datePickers.push(document.getElementsByName("vgt-ended_at"));
    datePickers.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT
      });
    });
  },
  methods: {
    onPublish() {
      this.matchesToPublish = [];
      this.loadItems();
    },
    publishMatch (matchId, match) {
      this.matchesToPublish = [match];
      this.$nextTick(() => {
        this.$refs.publishMatchModal.open();
      });
    },
    unLockMatch (matchId, match) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to unlock this match?', {
          title: 'Unlock this Match ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unlock Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
          id: 'unlock-modal'
        })
        .then(async value => {
          if(value) {
            const data = { ...match, status_id: matchStatus.NONE };
            try {
              this.$bvModal.show('updating-modal');
              await matchesService.patchUpdateMatch(this.defaultProgramId, matchId, data);
              this.$toast(makeSuccessToast('Match Unlocked successfully.'));
              this.loadItems();
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Match not unlocked.'));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });         
    },
    async unpublishMatch(matchId, match) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to Unpublish this match? This will sever the match and cannot be undone. Matches that are over 30 days into a program will still be counted in your program match capacity. Do you wish to continue?', {
          title: 'Unpublish Match?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unpublish Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            const data = { ...match, status_id: matchStatus.CANCELLED};
            try {
              this.$bvModal.show('updating-modal');
              const result = await matchesService.patchUpdateMatch(this.defaultProgramId, matchId, data);
              if (result) {
                this.$toast(makeSuccessToast('Match Unpublished successfully.'));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });
    },
    surveysCount (value) {
      return `${value.completed_surveys_count} / ${value.surveys_count}`;
    },
    trainingsCount(value) {
      return `${value?.completed_trainings_count ? value.completed_trainings_count : 0} / ${value?.trainings_count ? value.trainings_count : 0}`;
    },
    formatDuration(value) {
      if(Number(value.duration) === 0) {
        return "< 1d ";
      }
      return getFormatedStringFromDays(value.duration);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
  
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
  
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
  
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "status",
        "full_name",
        "email",
        "role",
        "matches",
        "created_at"
      ]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await matchesService.listUserMatches(this.defaultProgramId, this.user.id, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
              
    },
    userRole(userRoles) {
      if(userRoles.length > 0) {
        const roles = userRoles.map(r => userRolesDisplay[r.role.id]);
        const uniqueRoles = [...new Set(roles)];
        return uniqueRoles.join(", ");
      }
      return '';
    },
    editParticipant(id) {
      this.$emit('editParticipant', id);
    },
    menteeInMatch(match) {
      const mentee =  match.match_users.filter(m => Number(m.role_id) === Number(userRoles.MENTEE)).shift();
      if(mentee) {
        return mentee.user.full_name;
      }
      return '';
    },
    mentorInMatch(match) {
      const mentor =  match.match_users.filter(m => Number(m.role_id) === Number(userRoles.MENTOR)).shift();
      if(mentor) {
        return mentor.user.full_name;
      }
      return '';
    },
    openCompareModal(args) {
      const { match_users, compatibility } = args;
      // eslint-disable-next-line camelcase
      this.matchUsers = match_users;
      this.comparePercentage = compatibility;
      this.$refs.userAppSummary.show();
    },
    clearModal () {
      this.matchUsers = [];
      this.comparePercentage = 0;
    },
  },
  setup() {
    return {
      roleAlias,
      userRoles
    };
  }
};
</script>
  
  <style lang="scss">
  @import '@/assets/scss/vue/libs/vue-good-table.scss';
  .nav .dropdown-toggle:not(.active)::after {
    background-image: none !important;
  }
  </style>
  <style lang="scss" scoped>
  .action-menu {
    display: inline-block;
  }
  </style>
  